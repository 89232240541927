// TODO: import `useObjectRef` from `'react-aria'` once it stops throwing a TS error.
import { ReactComponent as KeyboardArrowDownIcon } from '@material-design-icons/svg/round/keyboard_arrow_down.svg';
import { useObjectRef } from '@react-aria/utils';
import clsx from 'clsx';
import { FC, forwardRef, Key, SVGProps, useEffect, useRef } from 'react';
import { AriaComboBoxProps, Filter, useComboBox, useFilter } from 'react-aria';
import { useComboBoxState } from 'react-stately';
import useSize from 'src/hooks/useSize';
import isNonEmptyString from 'src/utils/isNonEmptyString';

import ListBox from './ListBox';
import * as S from './styles';

interface Props extends AriaComboBoxProps<object> {
  caption?: string;
  className?: string;
  filter?: Filter['contains'] | Filter['endsWith'] | Filter['startsWith'];
  hideRequiredIndicator?: boolean;
  icon?: FC<SVGProps<SVGSVGElement>>;
  showDropdownButton?: boolean;
}

const ComboBox = forwardRef<HTMLInputElement, Props>(
  (
    {
      caption,
      className,
      filter,
      hideRequiredIndicator = false,
      icon,
      showDropdownButton = false,
      ...ariaComboBoxProps
    }: Props,
    forwardedRef
  ) => {
    const {
      description,
      errorMessage,
      isDisabled,
      isInvalid,
      isRequired = false,
      label
    } = ariaComboBoxProps;

    const { startsWith } = useFilter({ sensitivity: 'base' });
    const state = useComboBoxState({ ...ariaComboBoxProps, defaultFilter: filter ?? startsWith });

    const inputRef = useObjectRef(forwardedRef);

    const buttonRef = useRef<HTMLButtonElement>(null);
    const listBoxRef = useRef<HTMLUListElement>(null);
    const popoverRef = useRef<HTMLDivElement>(null);

    const {
      buttonProps,
      descriptionProps,
      errorMessageProps,
      inputProps,
      labelProps,
      listBoxProps
    } = useComboBox(
      {
        ...ariaComboBoxProps,
        buttonRef: showDropdownButton ? buttonRef : undefined,
        inputRef,
        listBoxRef,
        popoverRef
      },
      state
    );

    const size = useSize(inputRef);

    useEffect(() => {
      /* NOTE:
        This is a workaround to open the dropdown when the user types
        in the input field. This is necessary to make Cypress tests less
        flaky and does not affect the user experience since this is
        expected behavior.
      */
      if (!state.isOpen && state.inputValue !== '' && (state.selectedKey as Key | null) === null) {
        state.open();
      }
    }, [state, state.isOpen, state.inputValue, state.selectedKey]);

    return (
      <S.ComboBox
        className={clsx(className, { 'is-disabled': isDisabled, 'is-invalid': isInvalid })}
      >
        {isNonEmptyString(label) && (
          <S.Label {...labelProps}>
            {label}
            {isRequired && !hideRequiredIndicator && '*'}
            {isNonEmptyString(caption) && <S.Caption>{caption}</S.Caption>}
          </S.Label>
        )}
        <S.Container>
          <S.Input
            {...inputProps}
            ref={inputRef}
            data-hj-allow
          />
          {icon && (
            <S.Icon
              aria-hidden="true"
              as={icon}
            />
          )}
          {errorMessage !== undefined ? (
            <S.ErrorMessage {...errorMessageProps}>
              {typeof errorMessage === 'function'
                ? errorMessage(state.realtimeValidation)
                : errorMessage}
            </S.ErrorMessage>
          ) : (
            isNonEmptyString(description) && (
              <S.Description {...descriptionProps}>{description}</S.Description>
            )
          )}
          {showDropdownButton && (
            <S.DropdownButton
              {...buttonProps}
              ref={buttonRef}
              className={clsx({ 'is-disabled': isDisabled })}
            >
              <KeyboardArrowDownIcon aria-hidden="true" />
            </S.DropdownButton>
          )}
          {state.isOpen && (
            <S.Popover
              $width={size.width}
              isNonModal
              placement="bottom start"
              popoverRef={popoverRef}
              shouldFlip={false} // TODO: delete this line once https://github.com/adobe/react-spectrum/issues/1924 is fixed.
              state={state}
              triggerRef={inputRef}
            >
              <ListBox
                data-cy="combobox-options"
                {...listBoxProps}
                listBoxRef={listBoxRef}
                state={state}
              />
            </S.Popover>
          )}
        </S.Container>
      </S.ComboBox>
    );
  }
);

ComboBox.displayName = 'ComboBox';

export default ComboBox;
