import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useState } from 'react';
import { useIsFirstRender } from 'usehooks-ts';

import { generateNetworkOverview, NetworkOverview } from '../utils';

import useApiRequest, { filtersToParams } from './useApiRequest';

type FetchNetworkOverview = (
  filters: NetworkOverviewFilters,
  skipSettingIsFetching?: boolean
) => Promise<void>;

interface NetworkOverviewFilters {
  'benchmark_communities[]'?: string[];
  org_is_on_violet?: boolean;
  'organizations[]'?: string[];
  'specialties[]'?: string[];
  'states[]'?: string[];
}

interface UseGetNetworkOverview {
  (filters?: NetworkOverviewFilters): {
    isFetching: boolean;
    networkOverview: NetworkOverview | undefined;
    updateNetworkOverviewFilters: (newFilters: Partial<NetworkOverviewFilters>) => void;
  };
}

const useGetNetworkOverview: UseGetNetworkOverview = (filters = {}) => {
  const isFirstRender = useIsFirstRender();
  const [networkOverview, setNetworkOverview] = useState<NetworkOverview | undefined>();
  const [isFetching, setIsFetching] = useState(false);
  const [previousFilters, setPreviousFilters] = useState<NetworkOverviewFilters>(filters);
  const { getRequest, reportError } = useApiRequest();

  const fetchNetworkOverview: FetchNetworkOverview = useCallback(
    async (filters: NetworkOverviewFilters) => {
      setIsFetching(true);

      const params = filtersToParams(filters);

      const url: RequestInfo = `${process.env.REACT_APP_API_BASE_PATH}/networks/overview${
        params ? `?${params}` : ''
      }`;

      try {
        const { data } = (await getRequest(url)) as { data?: APINetworksOverview['data'] };
        setNetworkOverview(data !== undefined ? generateNetworkOverview(data) : undefined);
      } catch (error) {
        reportError(error);
      }

      setIsFetching(false);
    },
    [getRequest, reportError]
  );

  useEffect(() => {
    if (!isFirstRender) return;
    (async () => {
      await fetchNetworkOverview(filters);
    })();
  }, [fetchNetworkOverview, isFirstRender, filters]);

  const updateNetworkOverviewFilters = (newFilters: Partial<NetworkOverviewFilters>) => {
    Object.keys(newFilters).forEach(key =>
      newFilters[key as keyof NetworkOverviewFilters] === undefined
        ? delete newFilters[key as keyof NetworkOverviewFilters]
        : {}
    );
    if (isEqual(newFilters, previousFilters)) return;
    setPreviousFilters({ ...newFilters });
    fetchNetworkOverview(newFilters);
  };

  return {
    isFetching,
    networkOverview,
    updateNetworkOverviewFilters
  };
};

export default useGetNetworkOverview;
