import { Key } from 'react';

export const filtersObjectToParams = (appliedFilters: Set<Key>) => {
  const fromObjectToParamsKeyValuePairs = Array.from(new Set(appliedFilters))
    .map(key => {
      // converts our filters to array of key-value pairs
      // i.e. 'Organization:124' => ['organizations[]', '124']
      // i.e. 'State:CA' => ['states[]', 'CA']
      // eslint-disable-next-line prefer-const
      let [paramKey, paramValue] = key.toString().split(':');
      const [newKey, value] = networkFiltersToSearch(paramKey, paramValue);
      return [newKey, value] as [string, string];
    })
    // removes any filters that are empty strings or null
    .filter(([_key, value]) => value !== 'null' && value !== '' && value !== 'undefined')
    // combines duplicate keys into a single key with multiple values
    .reduce((acc, [newKey, value]) => {
      if (acc.has(newKey)) {
        acc.set(newKey, `${acc.get(newKey)},${value}`);
      } else {
        acc.set(newKey, value);
      }
      return acc;
    }, new Map<string, string>())
    // converts the map back to an array of key-value pairs
    .entries();
  return Array.from(fromObjectToParamsKeyValuePairs);
};

const networkFiltersToSearch = (key: string, value: string) => {
  let mappedKey;
  switch (key) {
    case 'State':
      mappedKey = 'states[]';
      break;
    case 'Organization':
      mappedKey = 'organizations[]';
      break;
    case 'Specialty':
      mappedKey = 'specialties[]';
      break;
    case 'Provider':
      mappedKey = 'user_name';
      break;
    case 'Range':
      mappedKey = 'date_range';
      break;
    case 'Name':
      mappedKey = 'resource_name';
      break;
    case 'Community':
      if (window.location.pathname.includes('education-engagement')) {
        mappedKey = 'communities[]';
      } else {
        mappedKey = 'benchmark_communities[]';
      }
      break;
    case 'Organizations on Violet':
      mappedKey = 'org_is_on_violet';
      break;
    default:
      mappedKey = key;
      break;
  }
  return [mappedKey, value];
};

export const networkSearchToFilters = (key: string, value: string) => {
  let mappedKey;
  switch (key) {
    case 'states[]':
      mappedKey = 'State';
      break;
    case 'organizations[]':
      mappedKey = 'Organization';
      break;
    case 'specialties[]':
      mappedKey = 'Specialty';
      break;
    case 'user_name':
      mappedKey = 'Provider';
      break;
    case 'date_range':
      mappedKey = 'Range';
      break;
    case 'resource_name':
      mappedKey = 'Name';
      break;
    case 'benchmark_communities[]':
      mappedKey = 'Community';
      break;
    case 'communities[]':
      mappedKey = 'Community';
      break;
    case 'org_is_on_violet':
      mappedKey = 'Organizations on Violet';
      break;
    default:
      mappedKey = key;
      break;
  }
  return `${mappedKey}:${value}`;
};

export const sortParams = (params: [string, string][]) => {
  const searchParams = new URLSearchParams();
  params.forEach(([key, value]) => searchParams.set(key, value));
  params.sort();
  params.filter(([_key, value]) => value !== 'null' && value !== '' && value !== 'undefined');
  return params;
};
